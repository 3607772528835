<template>
    <footer> <p> Sistema de Revisão de carros</p></footer>
</template>

<script>
export default {
    name: "Main-Footer",

}
</script>

<style scoped>
    footer {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        background-color: black;

        display: block;
        text-align: center;
        border-top: 1px solid gray;
    }


</style>